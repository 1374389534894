<template>

    <b-card>


      <b-row>

        <p v-if="!loading" style="    cursor: pointer; margin: 0px; position: absolute; right: 15px; z-index: 1;" @click="refresh()" > 
                                                            <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            size="16"
                                                            style="margin-right:5px"
                                                            />  </p>


      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="height: 80px; display:flex">
        <div style="margin:auto">
                
                      <b-skeleton v-if="loading" width="100%" height="38px" style="margin-bottom:0px !important" ></b-skeleton>
                       
                      <p v-else style=" margin-top: 0px;font-size:30px;font-weight: bold;text-align: center;" >{{ cantFondos }}</p>
                      <p style="margin-bottom:0px; font-size:12px;text-align: center; line-height: 14px; ">Total de fondos de ahorros pendientes por ejecutarse</p>
              
        </div>
   
      </b-col>

    
    </b-row>
 
  
        </b-card>
      
         
      
</template>
      
      <script>
      import {
        BButton, BSkeleton, BRow, VBPopover, BCol, BCard,
      } from 'bootstrap-vue'

      
      export default {
        components: {
         
            BSkeleton,
          BButton,
          BRow,
          BCol,
          BCard,
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['idAdmin','tokenAuth','userId'],
        data() {
      
      
          return {
            loading:true,
            cantFondos:0,
            showFondo:false,
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

            this.checkFondos();

      
        },
        methods: {

          refresh(){
            this.$eventBus.$emit('reiniciarFondoAhorrosAdmin')
          },

          checkFondos(){

            const data_json = { userId: this.userId, idAdmin: this.idAdmin }
                    const data_string = JSON.stringify(data_json)

            
                    const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                    this.$https.post('/administracion/checkFondosAhorros/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                    if (response.data.code == 200) {
                    this.loading = false;

                    this.cantFondos= response.data.cantFondos;

                    } else {

                  


                    if (response.data.code == 401) {

                        this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                    this.checkFondos();
                    }
                    }
                    }).catch(error => {
                    this.checkFondos();
                    })
                    },
  

      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      
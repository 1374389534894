<template>


    <b-row>

    
    
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
    
            <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
                <feather-icon
                            icon="ListIcon"
                            size="21"
                            class="color_icon"
                            /> Consulta de datos
                    </h4>
            
            </b-col>        

            <b-col  cols="12" sm="12" md="12" xl="3" lg="3"> </b-col>
            
            
            <b-col  cols="12" sm="12" md="12" xl="6" lg="6">

              <p style="margin-top: 15px; font-weight: bold;margin-bottom:5px">
                   
                            Ingrese el nro. de cédula:</p>



                <b-form
                autocomplete="off"
                @submit.prevent="onSubmit"
                
              >

              <b-row>

                <b-col  cols="12" sm="12" md="12" xl="6" lg="6" style="margin-bottom:15px">

          
                  
                    <b-form-input
                    v-model="cedula"
                    placeholder="Ingrese el nro. de cédula"
                    required
                    type="text"
                    />


                </b-col>

                <b-col  cols="12" sm="12" md="12" xl="6" lg="6" >

                    <b-button
                        id="btn_search"
                        ref="btn_search"
                        type="submit"
                        variant="primary"
                        :disabled="isDisabled"
                        block
                    >

                     Consultar datos

              </b-button>


                </b-col>

              </b-row>

              
             

              </b-form>





            </b-col>

            <b-col  cols="12" sm="12" md="12" xl="3" lg="3"> 
              
            </b-col> 

           
            <b-col v-if="isSearch" cols="12" sm="12" md="6" xl="6" lg="6">

                <p style="text-align: center; font-weight: bold;font-size:16px">Información general</p>

                <vue-json-pretty :height="400" :virtual="true"  :showLine="false"  :data="dataGeneral" />

            </b-col>

            <b-col v-if="isSearch" cols="12" sm="12" md="6" xl="6" lg="6">
                <p style="text-align: center; font-weight: bold;font-size:16px">Información detallada</p>

                <vue-json-pretty :height="400" :virtual="true" :showLine="false"  :data="dataDetallado" />

                </b-col>
           
    
     
      </b-row>
        
    </template>
        
        <script>
        import {
          BButton, BRow, BCol,BSkeleton, BCard, BFormInput, BForm
        } from 'bootstrap-vue'

        import VueJsonPretty from 'vue-json-pretty';
        import 'vue-json-pretty/lib/styles.css';
        export default {
          components: {
            BButton,
            VueJsonPretty, 
          
            BSkeleton,
            BFormInput,
            BRow,
            BForm,
            BCol,
            BCard,

          },
          props: ['userId','tokenAuth', 'idAdmin'],
          data() {
        

        
            return {

               isDisabled:false,
               cedula:"",
               isSearch:false,
               dataGeneral:{},
               dataDetallado:{}
               
            }
        
        
          },
          computed: {
        
          },
          watch: {
        
          },
          mounted() {
    


        
          },
          methods: {

            onSubmit(event){
                event.preventDefault();

                this.isDisabled= true;

                const data_json = { userId: this.userId, idAdmin: this.idAdmin, cedula: this.cedula }
                    const data_string = JSON.stringify(data_json)

                    document.getElementById('btn_search').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Buscando'

                    const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                    this.$https.post('/administracion/consultaBuroAdmin/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                        this.isDisabled=false;

                        document.getElementById('btn_search').innerHTML = 'Consultar datos';

                   
                      

                        
                        if (response.data.code == 200) {
                            this.isSearch=true;
                            this.dataGeneral= response.data["dataGeneral"];

                            this.dataDetallado= response.data["dataDetallado"];
                           

                            this.cedula="";

                    
                     

                        
                        } else {
                        
                            
                            this.isSearch=false;

                        if (response.data.code == 401) {

                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                            
                            localStorage.removeItem('userData')

                              

                            




                            
                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            } else {

                                if (response.data.code == 403) {

                                this.$router.push({ name: 'dashboard-analytics' })

                                }else{

                                    this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                        })

                                }

                        



                            } 
                            
                        
                        }
                    }).catch(error => {
                        this.$toast.error("Ocurrió un error inesperado", {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                        })
                    })

            }
        
       
        }
    }
        </script>
        
        <style lang="scss">

        </style>
<template>

    <b-row>
        <b-col cols="12" sm="12" md="4" lg="3" xl="3">

            <fondo-ahorros :key="componentKeyFondoAhorroAdmin"  :user-id="userId" :token-auth="tokenAuth" :id-admin="idAdmin" />



        </b-col>


        <b-col cols="12" sm="12" md="4" lg="4" xl="4">

        <mensajeria :key="componentKeyMensajeria"  :user-id="userId" :token-auth="tokenAuth" :id-admin="idAdmin" />



        </b-col>


        <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding: 0px;">

          <b-tabs
          style="padding: 0px;"
            class="row"
                content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-md-0 sin_padding"
                pills
      fill  
      nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 card3 sin_padding"

              align="center"
              >

                <b-tab>

                  <template #title>
                    <feather-icon
                                                      icon="StarIcon"
                                                      size="15"

                                                      class="margin-icon-profile"
                                                      />

                    <span class="titulo_menu">Suscripciones en negociación </span>
                  </template>

                  <b-card>
                    <listado-suscriptores :key="componentKeyListadoSuscripciones"  :user-id="userId" :token-auth="tokenAuth" :id-admin="idAdmin" />


                  </b-card>

               
                
             

              

                </b-tab>

                <b-tab @click="FinalizadosLoad">
                  
                  <template #title>

                    <feather-icon
                                                      icon="CheckCircleIcon"
                                                      size="15"

                                                      class="margin-icon-profile"
                                                      />

                  
                    <span class="titulo_menu" > Suscripciones finalizadas  </span>
                </template>
              
                <b-card>
                    <listado-suscriptores-finalizados v-if="ifFinalizados" :key="componentKeyListadoSuscripcionesFinalizados"  :user-id="userId" :token-auth="tokenAuth" :id-admin="idAdmin" />


                  </b-card>

                </b-tab>

              

    

  </b-tabs>



        </b-col>
    </b-row>
  
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BTabs, BTab
      } from 'bootstrap-vue'
      
      import FondoAhorros from './FondoAhorros.vue'
      import ListadoSuscriptores from './ListadoSuscriptores.vue'
      import ListadoSuscriptoresFinalizados from './ListadoSuscriptoresFinalizados.vue'
      
      import Mensajeria from './Mensajeria.vue'
      
      export default {
        components: {
          BTabs, BTab,
            FondoAhorros,
            Mensajeria,
          BButton,
          BRow,
          BCol,
          BCard,
          ListadoSuscriptores,
          ListadoSuscriptoresFinalizados
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['idAdmin','tokenAuth','userId'],
        data() {
      
      
          return {
      
            componentKeyFondoAhorroAdmin:100,
            componentKeyListadoSuscripciones:200,
            componentKeyListadoSuscripcionesFinalizados:300,
            componentKeyMensajeria:400,
            ifFinalizados:false,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          

          this.$eventBus.$on('reiniciarMensajeriaAdmin', () => {
      this.componentKeyMensajeria += 1

    });

            this.$eventBus.$on('reiniciarFondoAhorrosAdmin', () => {
      this.componentKeyFondoAhorroAdmin += 1

    });

    this.$eventBus.$on('reiniciarListadoSuscripciones', () => {
      this.componentKeyListadoSuscripciones += 1
    });

    this.$eventBus.$on('reiniciarListadoSuscripcionesFinalizados', () => {
      this.componentKeyListadoSuscripcionesFinalizados += 1
    });

    

      
        },
        methods: {
  
          FinalizadosLoad(){
            this.ifFinalizados=true;
          },
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      
<template>

  <div>

    <div v-if="buscarPlan">
                                    <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
                                  <b-skeleton  width="100%"  height="20px" style="margin-bottom:5px"></b-skeleton>
                                  
                                  </div>
                                 <div v-else style="margin-bottom:0px">

                                  <div v-if="hasPlan">
                                    <b-badge   variant="success">
                                        Activado
                                      </b-badge>
                                  </div>

                                  <div  v-else>

                                    <p style="margin-bottom:10px">
                                      <b-badge  variant="danger">
                                        Inactivo
                                      </b-badge>
                                    </p>

                                  

                                      
                     
                                  <b-button
                                  style="margin-top:0px"
                                            variant="outline-secondary"
                                            
                                            size="sm"
                                            @click="activatePlan"
                                        >

                                        Activar plan

                                  </b-button>

                                  </div>

                                
                                </div> 


  </div>
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard, BSkeleton, BAlert, BForm, BFormSelect, BBadge
      } from 'bootstrap-vue'
      
      import PincodeInput from 'vue-pincode-input'
      
      export default {
        components: {
            BAlert,
            BBadge,
            BSkeleton,
          BButton,
          BRow,
          BFormSelect,
          BCol,
          BCard,
          PincodeInput,
          BForm
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId','tokenAuth', 'idAdmin','userIdUser', 'userPromoter'],
        data() {
      
      
          return {
            buscarPlan:true,
            hasPlan:false,
            pin:"",
            plan:"",
            dateActivate:"",
            dateRenovate:"",
            isDisabled:false,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

            this.loadPlan();
      
        },
        methods: {

          activatePlan(){

             
            this.$swal({
              title: "¿Seguro que deseas activarle el plan negocios a este usuario?",
              text: "El usuario debe tener su perfil verificado y saldo en su billetera para el pago, caso contrario, no se efectuará la compra de esta suscripción",
                input: 'password',
                inputPlaceholder: "Ingrese su código PIN",
                inputAttributes: {
                    autocapitalize: 'off',
                    maxlength: 6,
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Activar",
                cancelButtonText: 'Cancelar',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: (pin) => {

                    if (pin != "") {

                        const data_json = { userId: this.userId, userIdUser:this.userIdUser,idAdmin:this.idAdmin, pin:pin }
                            const data_string = JSON.stringify(data_json)
                
                        
                
                            const dataEncripted = this.$encryptAdmin.encrypt(data_string)
                
                            return this.$https.post('/administracion/updatePlan/', {tokenAuth: this.tokenAuth, dataEncripted1: dataEncripted }).then(response => response.data).catch(error => {
                                this.$swal.showValidationMessage(
                                error,
                                )
                            })


                    } else {
                        this.$swal.showValidationMessage('El motivo del rechazo es obligatorio')   
                   }
               
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
              }).then(result => {
    
                this.$swal.close()
    
                if(result.isConfirmed){
    
       
    
                 
                  if (result.value.code == 200) {
    
                          this.$toast.success(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                          });

   
                          
                          this.$eventBus.$emit('reiniciarAdminChangePlan')
                          this.$eventBus.$emit('reiniciarSuscripcionesActivasAdmin')
            
                          } else {

                       
                            this.$toast.error(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
    
                            if (result.data.code == 401) {
                              
                              localStorage.removeItem('userData')


                              
    
                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                            }
                          }
    
                }
                
    
    
    
          
              })

          },



loadPlan(){

this.buscarPlan=true;


const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser, userPromoter: this.userPromoter }
        const data_string = JSON.stringify(data_json)

     
        const dataEncripted = this.$encryptAdmin.encrypt(data_string);

        this.$https.post('/administracion/searchPlan/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

      
            if (response.data.code == 200) {

              this.buscarPlan= false;
              this.hasPlan=response.data["hasPlan"];
            

              
             // this.$eventBus.$emit('showButtonRegisterNegocio')

            
            } else {
            
                


            if (response.data.code == 401) {

                this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                })

                
                localStorage.removeItem('userData')



                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
                } else {

                this.loadPlan();



                } 
                
            
            }
        }).catch(error => {
          this.loadPlan();
        })

}    
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      